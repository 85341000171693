import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors, icons, components } from 'herald-fe-shared';

export const StyledSquareItem = styled.div`
  padding: 2rem;
  position: relative;

  .square__item__indicator {
    position: absolute;
    left: 0;
    width: 1px;
    border-left: 1px solid ${colors.PURPLE_LIGHT()};
  }

  @media screen and (max-width: 500px) {
  }

  @media screen and (max-width: 400px) {
  }
`;

export const SquareItem: React.FC<any> = (props) => {
  const { icon: Icon, title, description, top, height, link, step } = props;
  return (
    <StyledSquareItem className="square__item">
      <div
        className="square__item__indicator"
        style={{ top: top || 32, height: height || 32 }}
      />
      <Icon className="square__item__icon" />

      {link ? (
        <Link to={link}>
          <h4 className="square__item__title">
            {title} <icons.Arrow />
          </h4>
        </Link>
      ) : (
        <h4 className="square__item__title">{title}</h4>
      )}
      <p className="square__item__description">{description}</p>
      {step && (
        <components.Pill
          colorFill={colors.PURPLE_LIGHT}
          style={{ border: 'none', marginTop: 6 }}
        >
          Step {step}
        </components.Pill>
      )}
    </StyledSquareItem>
  );
};

const Square = styled.div`
  margin: 0 -2rem;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(2, 50%);
  }
`;

export default Square;
