import * as React from 'react';

const SvgIllustrationNetwork = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 394 363" fill="none" {...props}>
    <circle
      cx={212.355}
      cy={181.5}
      r={180.824}
      stroke="#000"
      strokeWidth={1.353}
      strokeDasharray="3.38 13.53"
    />
    <path
      d="M48.703 245.932c46.988-2.218 175.51-38.357 313.692-165.165M85.91 39.022c81.877-.504 248.05 53.12 257.73 271.645"
      stroke="#000"
      strokeWidth={1.353}
      strokeDasharray="3.38 13.53"
    />
    <circle cx={42.048} cy={247.747} r={42.047} fill="#FCD7CB" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.854 246.429c.003-.15.004-.298.004-.446 0-6.812-2.935-13.642-9.437-13.642-6.502 0-10.278 6.83-10.278 13.642 0 .121 0 .243.003.365-.504-.656-1.197-1.297-1.87-1.063-.324.114-.593.935-.56 1.589.032.641.916 6.143 3.835 6.787.496 1.346 1.12 2.625 1.862 3.756v6.694l-12.988 3.737c-2.118.312-6.391 2.168-6.54 7.102v4.111l53.446.748s.56-9.372-3.457-10.559c-8.223-2.429-17.66-5.139-17.66-5.139v-5.407c1.18-1.546 2.062-3.528 2.662-5.643 2.954-.598 3.846-6.153 3.879-6.797.033-.654-.236-1.475-.56-1.589-.922-.321-1.882 1.001-2.341 1.754z"
      fill="#C16D6D"
    />
    <path
      d="M35.507 266.621l-.093-2.71v-6.447h.093s.199 2.338.374 3.831c.158 1.353.223 1.944.467 3.457.277 1.721 1.495 4.205 1.495 4.205l-2.336-2.336zM52.483 247.15l-.366.265a.21.21 0 00-.084.138l-.534 3.556a.21.21 0 00.048.167l.131.153a.209.209 0 00.368-.136v-.93l.181-2.179a.205.205 0 01.045-.113l.497-.621c.152-.19-.089-.442-.286-.3zM31.145 247.524l.366.264a.21.21 0 01.084.139l.534 3.556a.21.21 0 01-.048.167l-.131.153a.209.209 0 01-.368-.136v-.93l-.181-2.18a.21.21 0 00-.046-.113l-.496-.62c-.152-.19.088-.442.286-.3z"
      fill="#000"
      fillOpacity={0.05}
    />
    <path
      d="M48.214 258.959c-.66 8.596-4.672 8.721-6.727 11.306 5.307 2.243 6.79-3.955 6.727-6.354v-4.952z"
      fill="#000"
      fillOpacity={0.03}
    />
    <path
      d="M51.858 245.983c0 6.812-2.935 15.885-9.437 15.885-6.502 0-10.278-9.073-10.278-15.885 0-6.812 3.776-13.642 10.278-13.642 6.502 0 9.437 6.83 9.437 13.642z"
      fill="#fff"
      fillOpacity={0.07}
    />
    <path
      d="M35.413 264.11s.498 2.007 1.308 2.99c1.384 1.679 2.964 2.122 5.14 2.149 2.242.028 4.008-.333 5.325-2.149.725-.999 1.028-2.99 1.028-2.99s9.438 2.71 17.66 5.139c4.018 1.187 3.457 10.559 3.457 10.559S57.15 291.623 40.178 289.7c-18.968-2.149-24.294-9.437-24.294-9.437v-5.314c.15-4.934 4.423-6.79 6.541-7.101l12.988-3.738z"
      fill="#FBA78C"
    />
    <path
      d="M69.332 279.796l-6.074 4.579s2.91-4.07 3.27-7.102c.398-3.349-1.962-8.409-1.962-8.409 4.71.747 5.14 7.599 4.766 10.932z"
      fill="#000"
      fillOpacity={0.03}
    />
    <path
      d="M18.968 269.051c-3.438 1.569-3.52 8.534-3.083 11.586 0 0 1.65 1.319 2.803 2.149 1.286.926 3.55 2.056 3.55 2.056s-8.316-12.855 5.887-15.791c2.87-.594 11.493 0 11.493 0-2.616-.524-4.018-3.364-4.205-4.859-4.017.965-13.006 3.289-16.445 4.859z"
      fill="#000"
      fillOpacity={0.02}
    />
    <path
      d="M44.57 243.834c1.152-.031 3.214-.131 4.111 1.214M39.992 243.942c-1.153-.031-3.215-.131-4.112 1.215"
      stroke="#000"
      strokeWidth={0.627}
      strokeLinecap="round"
    />
    <ellipse cx={44.944} cy={247.757} rx={1.028} ry={1.215} fill="#000" />
    <ellipse cx={45.038} cy={247.766} rx={0.561} ry={0.663} fill="#fff" />
    <ellipse cx={39.526} cy={247.757} rx={1.028} ry={1.215} fill="#000" />
    <ellipse cx={39.619} cy={247.766} rx={0.561} ry={0.663} fill="#fff" />
    <path
      d="M41.487 249.253l-.187 4.205c.405.156 1.364.374 1.962 0"
      stroke="#000"
      strokeWidth={0.209}
    />
    <path
      d="M39.15 256.074c1.216.499 4.187 1.122 6.355-.373"
      stroke="#000"
      strokeWidth={0.418}
      strokeLinecap="round"
    />
    <path
      d="M32.984 241.685c-.374 1.296-.405 5.637-.56 7.942-2.617-5.232-2.835-12.894-2.617-16.071 3.289-10.391 11.898-5.762 15.791-2.149 12.558-1.047 9.468 9.655 6.354 15.137-.654 3.819-.654-.505-.654-2.149 0-1.645-1.122-3.738-1.682-4.579-1.196-.149-2.554-1.931-3.084-2.803H37.19c-1.028.28-1.054-.187-1.028.467.093 2.336-2.864 3.12-3.177 4.205z"
      fill="#090D31"
    />
    <path
      d="M48.215 233.17c1.401.187 5.7 0 4.765 4.205-.41-2.803-2.672-3.233-4.765-4.205z"
      fill="#fff"
      fillOpacity={0.12}
    />
    <path
      d="M34.012 229.236c2.106-1.24 6.26 0 6.26 0s-3.56.019-5.42 1.122c-2.102 1.247-3.83 4.952-3.83 4.952s.712-4.732 2.99-6.074z"
      fill="#fff"
      fillOpacity={0.11}
    />
    <circle cx={355.438} cy={65.037} r={35.998} fill="#B9F6F2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M363.544 63.082l-.027-.331c0-5.832-2.513-10.993-8.08-10.993-5.566 0-8.799 5.16-8.799 10.993l.001.31c-.431-.56-1.024-1.108-1.599-.908-.278.097-.508.8-.48 1.36.026.504.667 4.523 2.709 5.6.419 1.76 1.097 3.396 2.169 4.667v4.49l-11.12 3.2c-1.813.266-5.471 1.855-5.599 6.079v3.52l44.797.767s1.44-8.15-2-9.167c-7.04-2.08-15.119-4.4-15.119-4.4v-3.844c1.419-1.342 2.414-3.34 2.893-5.837 2.041-1.078 2.681-5.093 2.707-5.597.028-.56-.202-1.263-.48-1.36-.769-.268-1.569.8-1.973 1.451z"
      fill="#D5645D"
    />
    <path
      d="M351.518 79.916c.242-2.468-2.08-6-2.08-6v4.4l-.24.72 1.6 3.44s.618-1.526.72-2.56zM356.397 82.796c1.152-.128 3.04-4.8 3.84-7.52l.16-.8v3.76l.56 5.84-7.36.72 2.8-2z"
      fill="#616060"
      fillOpacity={0.04}
    />
    <path
      d="M363.517 62.75c.72 8.15-2.513 13.6-8.08 13.6-7.679 0-8.799-7.768-8.799-13.6 0-5.831 3.233-11.312 8.799-11.312 5.567 0 8.08 5.48 8.08 11.313z"
      fill="#fff"
      fillOpacity={0.07}
    />
    <path
      d="M349.438 78.269s.425 1.718 1.119 2.56c1.185 1.437 2.538 1.816 4.4 1.84 1.92.024 3.432-.286 4.56-1.84.621-.856.88-2.56.88-2.56s8.079 2.32 15.119 4.4c3.439 1.016 2.8 10.206 2.8 10.206s-8.48 7.999-23.359 7.999c-16.028 0-22.238-8-22.238-8v-5.326c.128-4.223 3.786-5.813 5.599-6.08l11.12-3.199z"
      fill="#24A8A8"
    />
    <path
      d="M373.436 84.476l-11.359-5.6s11.759 3.36 14.079 4.08c2.32.72 2.24 9.92 2.24 9.92l-2.88 1.92-2.08-10.32z"
      fill="#000"
      fillOpacity={0.03}
    />
    <path
      d="M351.278 81.676l-16.799 12.719-1.68-1.36s-1.238-8.374 2.56-10.4c2.4-1.279 14.079-4.319 14.079-4.319.064.96 1.253 2.64 1.84 3.36z"
      fill="#000"
      fillOpacity={0.02}
    />
    <path
      d="M357.277 61.05c.987-.026 2.752-.112 3.52 1.04M353.357 61.143c-.986-.027-2.751-.112-3.519 1.04"
      stroke="#000"
      strokeWidth={0.537}
      strokeLinecap="round"
    />
    <ellipse cx={357.598} cy={64.27} rx={0.88} ry={1.04} fill="#000" />
    <ellipse cx={357.677} cy={64.278} rx={0.48} ry={0.567} fill="#fff" />
    <ellipse cx={352.958} cy={64.27} rx={0.88} ry={1.04} fill="#000" />
    <ellipse cx={353.038} cy={64.278} rx={0.48} ry={0.567} fill="#fff" />
    <path
      d="M355.277 65.517l-.8 3.633c.347.133 1.168.32 1.68 0M352.637 71.39c1.6.527 3.6.767 5.6-.353"
      stroke="#000"
      strokeWidth={0.179}
    />
    <path
      d="M362.877 48.67c-1.755-1.214-5.52 0-5.52 0s4.616-3.348 6.56-1.44c1.342 1.319.4 4.8.4 4.8s-.267-2.547-1.44-3.36z"
      fill="#fff"
      fillOpacity={0.08}
    />
    <path
      d="M362.092 56.428c-.32.704 1.46 8.426 1.594 10.399 0-4.48 1.94-8.513-.714-13.232-2.16-3.84-6.96-3.2-10.087-2.837-7.912.916-7.287 9.96-5.754 15.598.187-.026.396-2.6.396-4.009 0-1.408.406-5.2.886-5.92 1.024-.127 2.8-1.906 4.799.39 2 2.297 8.88-.39 8.88-.39z"
      fill="#091840"
    />
    <circle cx={336.985} cy={312.785} r={33.275} fill="#DCE0FD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M344.479 310.977c-.008-.101-.016-.204-.026-.306 0-5.391-2.322-8.904-7.468-8.904s-8.134 3.513-8.134 8.904l.001.286c-.398-.518-.946-1.024-1.477-.839-.258.09-.47.74-.444 1.258.023.466.616 4.18 2.503 5.175.388 1.627 1.014 3.14 2.005 4.315v4.15l-10.278 2.958c-1.676.247-5.058 1.716-5.176 5.62v3.253l41.409.709s1.331-7.533-1.849-8.473c-6.507-1.922-13.975-4.067-13.975-4.067v-3.553c1.312-1.241 2.231-3.087 2.674-5.396 1.886-.997 2.478-4.708 2.502-5.174.026-.518-.187-1.167-.444-1.257-.71-.248-1.45.74-1.823 1.341z"
      fill="#FAC0BC"
    />
    <path
      d="M333.362 326.538c.224-2.281-1.923-5.546-1.923-5.546v4.067l-.222.665 1.479 3.18s.572-1.411.666-2.366zM337.872 329.2c1.065-.118 2.81-4.436 3.549-6.95l.148-.74v3.476l.518 5.397-6.803.666 2.588-1.849z"
      fill="#616060"
      fillOpacity={0.04}
    />
    <path
      d="M344.453 310.67c.666 7.533-2.323 12.571-7.468 12.571-7.099 0-8.134-7.18-8.134-12.571 0-5.39 2.988-8.904 8.134-8.904 5.145 0 7.468 3.514 7.468 8.904z"
      fill="#fff"
      fillOpacity={0.07}
    />
    <path
      d="M331.439 325.016s.394 1.588 1.035 2.366c1.095 1.329 2.346 1.679 4.067 1.701 1.775.022 3.173-.264 4.215-1.701.574-.791.813-2.366.813-2.366s7.469 2.144 13.976 4.067c3.18.939 2.588 9.434 2.588 9.434s-7.838 7.395-21.592 7.395c-14.815 0-20.556-7.395-20.556-7.395v-4.923c.118-3.905 3.5-5.374 5.176-5.62l10.278-2.958z"
      fill="#FB3564"
    />
    <path
      d="M353.623 330.753l-10.501-5.176s10.87 3.106 13.015 3.771c2.144.666 2.07 9.169 2.07 9.169l-2.662 1.775-1.922-9.539z"
      fill="#000"
      fillOpacity={0.03}
    />
    <path
      d="M333.14 328.165l-15.529 11.757-1.552-1.257s-1.144-7.741 2.366-9.613c2.218-1.183 13.014-3.993 13.014-3.993.059.887 1.159 2.44 1.701 3.106z"
      fill="#000"
      fillOpacity={0.02}
    />
    <path
      d="M338.686 309.099c.912-.025 2.543-.104 3.253.961M335.062 309.184c-.912-.024-2.543-.103-3.253.962"
      stroke="#000"
      strokeWidth={0.496}
      strokeLinecap="round"
    />
    <ellipse cx={338.982} cy={312.077} rx={0.813} ry={0.961} fill="#000" />
    <ellipse cx={339.056} cy={312.083} rx={0.444} ry={0.524} fill="#fff" />
    <ellipse cx={334.693} cy={312.077} rx={0.813} ry={0.961} fill="#000" />
    <ellipse cx={334.767} cy={312.083} rx={0.444} ry={0.524} fill="#fff" />
    <path
      d="M336.837 313.228l-.739 3.358c.32.123 1.079.296 1.553 0M334.397 318.656c1.479.487 3.327.709 5.176-.326"
      stroke="#000"
      strokeWidth={0.165}
    />
    <path
      d="M343.862 297.656c-1.622-1.123-5.102 0-5.102 0s4.266-3.096 6.063-1.331c1.241 1.218.37 4.436.37 4.436s-.246-2.354-1.331-3.105z"
      fill="#fff"
      fillOpacity={0.08}
    />
    <circle cx={224.153} cy={174.543} r={28.133} fill="#FED3D3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M230.488 173.015a278.5 278.5 0 01-.021-.259c0-4.558-1.964-9.128-6.315-9.128-4.35 0-6.876 4.57-6.876 9.128v.242c-.337-.438-.8-.866-1.249-.709-.217.076-.397.625-.375 1.063.02.394.521 3.534 2.117 4.375.327 1.376.857 2.655 1.695 3.648v3.509l-8.69 2.501c-1.417.208-4.276 1.45-4.376 4.751v2.751l35.759.5s.375-6.27-2.313-7.064a1849.304 1849.304 0 00-11.816-3.439v-3.004c1.11-1.049 1.887-2.61 2.262-4.562 1.594-.843 2.095-3.981 2.115-4.374.022-.438-.158-.987-.375-1.063-.601-.21-1.226.626-1.542 1.134z"
      fill="#FBA19B"
    />
    <path
      d="M230.467 172.756c.562 6.369-1.964 10.628-6.314 10.628-6.002 0-6.877-6.071-6.877-10.628 0-4.558 2.526-9.128 6.877-9.128 4.35 0 6.314 4.57 6.314 9.128z"
      fill="#fff"
      fillOpacity={0.08}
    />
    <path
      d="M219.464 184.884s.333 1.343.875 2.001c.926 1.123 1.983 1.42 3.438 1.438 1.501.019 2.682-.223 3.564-1.438.485-.669.687-2.001.687-2.001s6.315 1.813 11.816 3.439c2.688.794 2.188 7.976 2.188 7.976s-6.627 6.252-18.255 6.252c-12.526 0-17.379-6.252-17.379-6.252v-4.163c.1-3.301 2.959-4.543 4.376-4.751l8.69-2.501z"
      fill="#FB8CB4"
    />
    <path
      d="M225.59 171.318c.771-.021 2.151-.087 2.751.813M222.527 171.39c-.771-.021-2.15-.087-2.751.813"
      stroke="#000"
      strokeWidth={0.28}
      strokeLinecap="round"
    />
    <ellipse cx={225.841} cy={173.945} rx={0.688} ry={0.813} fill="#000" />
    <ellipse cx={225.903} cy={173.949} rx={0.375} ry={0.443} fill="#fff" />
    <ellipse cx={222.214} cy={173.945} rx={0.688} ry={0.813} fill="#000" />
    <ellipse cx={222.277} cy={173.949} rx={0.375} ry={0.443} fill="#fff" />
    <path
      d="M223.527 174.944l-.125 2.813c.271.104.913.25 1.313 0"
      stroke="#000"
      strokeWidth={0.14}
    />
    <path
      d="M226.34 179.231c-1.688.6-3.251.625-4.376.276 0 0 .438 1.537 2.063 1.537 1.438 0 2.313-1.813 2.313-1.813z"
      fill="#EA7B7B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M225.193 161.701a2.625 2.625 0 10-2.449.069c-9.017 1.332-12.34 13.436-5.093 18.712h.437c1.101-.901.876-6.273.751-9.065 1.852.172 3.852-2.842 4.931-4.468.174-.262.324-.488.445-.659 2.436 0 3.032 1.275 3.498 2.272.3.641.545 1.167 1.191 1.167l.688 9.502h.625c6.023-3.788 6.125-15.656-5.024-17.53z"
      fill="#0D286E"
    />
    <circle cx={91.053} cy={43.862} r={30.552} fill="#FABBDA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.178 42.904l.003-.323c0-4.95-2.133-9.913-6.857-9.913-4.725 0-7.469 4.963-7.469 9.913l.003.265c-.366-.477-.87-.943-1.358-.773-.237.083-.432.68-.408 1.155.024.465.666 4.464 2.787 4.931.36.979.813 1.909 1.353 2.73v4.864l-9.438 2.716c-1.538.226-4.644 1.575-4.752 5.16v2.987l38.835.543s.408-6.81-2.512-7.672c-5.974-1.765-12.832-3.734-12.832-3.734v-3.929c.858-1.123 1.498-2.564 1.934-4.1 2.147-.435 2.795-4.471 2.819-4.94.024-.475-.171-1.071-.407-1.154-.67-.233-1.368.728-1.7 1.274z"
      fill="#F6A49D"
    />
    <path
      d="M98.182 42.58c0 4.95-2.133 11.543-6.858 11.543-4.724 0-7.468-6.593-7.468-11.542 0-4.95 2.744-9.913 7.468-9.913 4.725 0 6.858 4.963 6.858 9.913z"
      fill="#fff"
      fillOpacity={0.07}
    />
    <path
      d="M86.232 55.752s.362 1.458.95 2.173c1.006 1.22 2.154 1.542 3.735 1.561 1.63.02 2.913-.242 3.87-1.561.527-.726.747-2.173.747-2.173s6.857 1.97 12.832 3.734c2.919.863 2.512 7.673 2.512 7.673s-8.826 8.342-21.183 7.188c-13.85-1.294-17.653-7.188-17.653-7.188v-3.531c.109-3.585 3.214-4.934 4.753-5.16l9.437-2.716z"
      fill="#A28CFB"
    />
    <path
      d="M92.886 41.02c.837-.023 2.335-.096 2.987.882M89.559 41.098c-.837-.023-2.336-.095-2.987.882"
      stroke="#000"
      strokeWidth={0.304}
      strokeLinecap="round"
    />
    <ellipse cx={93.158} cy={43.872} rx={0.747} ry={0.883} fill="#000" />
    <ellipse cx={93.225} cy={43.878} rx={0.407} ry={0.481} fill="#fff" />
    <ellipse cx={89.219} cy={43.872} rx={0.747} ry={0.883} fill="#000" />
    <ellipse cx={89.288} cy={43.878} rx={0.407} ry={0.481} fill="#fff" />
    <path
      d="M90.645 44.957l-.135 3.056c.294.113.99.271 1.425 0M88.948 49.914c.883.362 3.042.814 4.617-.272"
      stroke="#000"
      strokeWidth={0.152}
    />
    <path
      d="M84.467 39.458c-.34.56-.158 3.748-.272 5.423-.271 1.417 1.63 5.227 2.173 6.178v4.685l-7.808 2.172s-.277-3.575 0-5.838c.338-2.756 2.047-4.077 1.901-6.85-.08-1.521-.39-2.28-.475-3.801-.18-3.232 2.037-6.527 2.037-6.527 3.327-7.468 8.78-5.536 11.61-2.91 1.358-.57 4.332-1.014 5.364 1.756.678 1.672.425 5.558 1.289 7.129.747 1.358.204 2.037 1.155 3.395.95 1.357-.624 2.085-.679 3.462-.103 2.57 1.938 3.685 2.172 6.246.136 1.48-.543 3.938-.543 3.938l-6.925-2.24v-3.802c.883-2.054 1.562-4.413 2.308-7.265.32-1.222-.746-1.987-.746-3.182 0-1.195-.136-3.472-.544-4.083-.475-1.281-1.787-.647-2.172-1.281 0 0-1.68-1.418-3.055-1.434-1.427-.018-1.562 2.036-1.562 2.036-.543 1.223-1.697 1.426-2.173 1.426-1.698 0-2.308.136-3.055 1.367z"
      fill="#000"
    />
  </svg>
);

export default SvgIllustrationNetwork;
