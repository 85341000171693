import * as React from 'react';

const SvgIllustrationSendFeedback = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 365 368" fill="none" {...props}>
    <g clipPath="url(#IllustrationSendFeedback_svg__clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.55 112.089v17.997h12.946l-.947-4.42-.948-4.42-1.065-4.797c10.314 1.514 8.775-11.06 6.117-19.831-4.294-9.346-14.84-7.894-17.365-1.263-2.154 4.576-.559 10.224.676 14.595.213.755.416 1.472.586 2.139zm62.833 22.734c-2.11-1.688-1.208 3.851-.425 8.666.154.949.304 1.869.425 2.7l-4.736 13.893 7.262 5.368 2.842-9.472 1.895-4.737c.28-.24.475-.536.665-.824.309-.467.604-.914 1.229-1.07 1.263-.316 7.262-6.946 5.052-10.42-1.768-2.778-6.631-4.841-7.894-4.104.073-.796.191-1.678.313-2.583.41-3.045.856-6.353-.313-7.521-1.515-1.516-3.473 8.104-3.789 14.208-.548 0-.62-.392-.733-1.001-.147-.795-.362-1.959-1.793-3.103zM157.241 241.228l.947 3.158c2.21 4.104 4.42 8.209 6.315 6.315 0 0-6.186-10.338-2.21-10.42.688-.014 1.039.704 1.409 1.458.405.828.831 1.699 1.748 1.699 1.263 0-.947-9.156-.947-9.156v-9.472h-7.262v16.418z"
        fill="#FFA9A4"
      />
      <path
        d="M203.971 126.929l-3.474-16.103-.947.947v17.051l4.421 2.21v-4.105z"
        fill="#000"
        fillOpacity={0.07}
      />
      <path
        d="M175.238 130.086l23.365-5.051c7.073 4.294 12.419 1.789 14.208 0l22.734 10.103c.253 3.031 7.683 25.681 11.682 37.258 2.242-6.793 6.002-17.968 8.342-24.312l-1.079-1.263 1.578-5.052 1.579 1.579c.253 1.263 8.736 11.472 12.946 16.418l1.578 1.895-1.578 3.473-1.632-1.263c-8.39 19.972-15.734 40.852-24.891 37.889-10.735-3.473-14.524-20.523-17.682-32.521 2.468 9.964 1.706 24.226 1.281 32.189l-.018.332c-8.84 3.789-36.521 1.579-49.256 0v-31.574c-3.031 5.557-6.736 33.258-8.525 51.15l-.947 8.21-17.682 1.263-1.578-7.894h1.578c0-28.417 13.998-71.884 23.997-92.829z"
        fill="#FFC98A"
      />
      <path
        d="M223.547 158.503l-1.263 44.205h5.683c1.263-2.779.105-22.102-.947-31.575 2.273 9.851 6.315 22.102 10.735 26.207l3.158 2.842-1.579-5.052s-3.158-11.683-11.367-26.838c-1.339-2.473-4.42-9.789-4.42-9.789zM164.818 223.231l5.052-2.21c2.526-27.154 5.894-42.731 8.525-50.204v30.628l4.737 1.894-2.211-51.15-4.42 9.156c-5.999 10.42-9.999 45.572-11.683 61.886z"
        fill="#000"
        fillOpacity={0.06}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.919 341.635v24.628H152.82v-3.157h.242c-.619-2.741-1.237-8.015 5.442-11.051 2.824-1.284 5.73-1.313 8.639-1.342 4.245-.042 8.497-.084 12.516-4.026.445-.437.908-.815 1.351-1.177 1.306-1.066 2.438-1.989 2.438-3.875v-7.262h15.471v7.262z"
        fill="#393939"
      />
      <path
        fill="#000"
        fillOpacity={0.23}
        d="M152.82 363.106h46.099v3.157H152.82z"
      />
      <path
        d="M213.443 363.737V334.689h15.471v7.262c0 2.526 2.029 3.325 3.789 5.052 6.772 6.644 14.209 2.21 21.155 5.367 6.946 3.158 5.999 8.736 5.368 11.367h-45.783z"
        fill="#393939"
      />
      <path
        fill="#000"
        fillOpacity={0.87}
        d="M259.542 363.421h-46.099v3.157h46.099z"
      />
      <path
        d="M194.182 88.093c-6.889 8.956.604 18.57 5.102 22.971h1.529c-.861-1.722-1.894-4.026-1.894-6.553 0-2.526 1.789-1.425 2.841-.947-.947-3.789-3.175-10.407 2.526-9.724 4.737.567 7.894-1.579 9.473 0 0 0 3.157 1.329 5.683-1.643s.728-7.616-2.526-10.104c-3.215-9.645-9.881-2.511-13.326 1.986-2.067-.918-7.207 2.77-9.408 4.014z"
        fill="#0D293E"
      />
      <path
        d="M204.286 233.651l6 102.932h-1.579l1.579 4.42h6.63l.947-4.42-5.367-49.888-3.789-26.206-4.421-26.838z"
        fill="#000"
        fillOpacity={0.13}
      />
      <path
        d="M231.44 46.414s3.339 17.883 12.314 21.47c7.681 3.071 13.582-7.757 20.839-3.788 9.659 5.281 2.211 28.101 2.211 28.101M289.853 44.836s-1.713 20.093 7.262 23.68c7.681 3.07 18.318-9.02 25.575-5.052 9.659 5.282 2.526 27.154 2.526 27.154"
        stroke="#000"
        strokeWidth={1.263}
        strokeDasharray="3.16 3.16"
      />
      <rect
        x={259.542}
        y={83.988}
        width={105.458}
        height={46.73}
        rx={5.052}
        fill="#F7F7F7"
      />
      <rect
        x={267.119}
        y={92.829}
        width={89.04}
        height={3.789}
        rx={1.894}
        fill="#E3E3E3"
      />
      <rect
        x={267.119}
        y={100.407}
        width={49.888}
        height={3.789}
        rx={1.894}
        fill="#E3E3E3"
      />
      <rect
        x={267.119}
        y={112.405}
        width={30.943}
        height={11.367}
        rx={5.683}
        fill="#18ABB3"
      />
      <circle cx={228.914} cy={36.311} r={23.681} fill="#F8C5B5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M245.333 53.36s-3.473-8.209-16.419-8.209c-13.928 0-17.05 7.262-17.05 7.262s3.789 4.42 7.578 5.684c.46.153.868.292 1.239.419 2.687.916 3.402 1.16 7.286 1.16 4.421 0 7.493-.492 11.367-2.21 2.909-1.29 5.999-4.105 5.999-4.105zm-66.306 148.085c-3.007 37.73-7.658 113.072-4.218 135.138h-1.15l2.21 4.42h25.891l2.211-4.42h-2.107l2.422-103.248 5.753 103.248h-1.332l1.578 4.42h24.628l1.895-4.42h-2.02l-2.085-73.253-4.42-61.885c-1.948.551-4.106 1.082-8.841 1.505.658-1.304 1.53-22.028 2.407-42.87.47-11.163.941-22.36 1.382-30.625l-3.789-1.579c1.516 2.526-1.894 51.298-3.789 75.367-6.092.406-9.546.462-18.945-.293 3.158-23.446 3.221-72.169-2.841-76.968l-3.158.631c2.21 2.229 9.852 20.914 2.526 76.337-.63-.064-1.5-.149-2.51-.247-3.678-.358-9.204-.895-11.698-1.258z"
        fill="#028B91"
      />
      <path
        d="M237.124 29.68h-11.683v16.734c2.327 2.116 3.64 2.504 5.999.947l-.631-5.999c6.315 1.516 6.63-7.157 6.315-11.682z"
        fill="#B58383"
      />
      <path
        d="M223.262 26.72c-3.996 5.194-.745 10.826 1.864 13.38l1.262.315c-.499-.999-.315-3.902-.315-5.367 0-1.466 1.407-.614 2.018-.337-.888-.666-2.437-3.287.666-4.495 2.999-1.168 6.472 1.358 8.325 1.358l2.252-2.526c3.596-1.731-.365-3.883-2.252-5.326-1.865-5.594-6.327-1.776-8.325.833-1.199-.533-4.218 1.443-5.495 2.164z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.333}
      />
      <circle cx={293.326} cy={23.681} r={23.681} fill="#F8C5B5" />
      <path
        d="M293.326 32.522c12.946 0 16.419 8.209 16.419 8.209s-3.09 2.814-5.999 4.105c-3.874 1.718-6.947 2.21-11.367 2.21-4.42 0-4.736-.316-8.525-1.579-3.789-1.263-7.578-5.683-7.578-5.683s3.122-7.262 17.05-7.262z"
        fill="#504DF5"
      />
      <path
        d="M301.535 17.05h-11.682v16.735c2.327 2.115 3.64 2.504 5.999.947l-.631-6c6.314 1.516 6.63-7.156 6.314-11.682z"
        fill="#F3F3F3"
      />
      <path
        d="M287.674 14.09c-3.996 5.194-.745 10.827 1.864 13.38l1.262.316c-.499-1-.316-3.903-.316-5.368 0-1.465 1.408-.614 2.019-.336-.888-.666-2.438-3.288.666-4.496 2.999-1.167 6.472 1.359 8.325 1.359l2.252-2.526c3.596-1.732-.365-3.884-2.252-5.327-1.865-5.594-6.327-1.776-8.325.833-1.199-.533-4.218 1.443-5.495 2.164z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.333}
      />
      <path
        d="M231.125 310.692l-5.684-108.3 2.526-1.263c2.021 5.304 5.999 80.83 5.999 119.035l-2.841-9.472zM182.816 233.335l-3.473-31.89c-1.263 5.367-6.631 66.306-5.052 135.454l1.894 4.104h13.261l-6.63-107.668z"
        fill="#000"
        fillOpacity={0.13}
      />
      <path
        d="M31.226 333.354v-69.392s13.879 42.936 19.083 66.356c2.032 9.146-6.072 23.853-6.072 23.853s-13.01-11.187-13.01-20.817z"
        fill="#2C8B74"
      />
      <path
        d="M38.599 300.393l-3.036-22.119 12.143 42.069-9.107-19.95z"
        fill="#000"
        fillOpacity={0.05}
      />
      <path
        d="M31.226 301.26c1.04 6.245 10.12 35.708 14.746 49.442l-1.735 3.035c-7.806-5.638-11.71-13.444-13.01-19.082V301.26z"
        fill="#000"
        fillOpacity={0.18}
      />
      <path
        d="M45.972 365.881L31.659 265.263"
        stroke="#000"
        strokeWidth={0.434}
      />
      <path
        d="M54.436 351.135c-2.581-6.624-4.646-32.725 7.742-84.137 7.886 24.663 17.379 76.02-7.742 84.137z"
        fill="#2C8B74"
      />
      <path
        d="M58.55 289.55c2.602 15.613-1.561 40.854-6.072 46.406-1.3-12.144 2.602-38.166 6.072-46.406z"
        fill="#000"
        fillOpacity={0.11}
      />
      <path
        d="M54.212 352.87c4.337-14.167 10.409-34.262 15.613-48.574 2.169 14.601 1.301 43.37-15.613 48.574z"
        fill="#000"
        fillOpacity={0.18}
      />
      <path
        d="M46.84 366.315c5.927-8.53 17.608-33.048 16.914-62.887-.353-15.179-1.59-38.31-1.735-35.129"
        stroke="#000"
        strokeWidth={0.434}
      />
      <path
        d="M21.685 344.196c3.817 11.45 15.324 15.758 25.155 21.685 0 0-7.213-17.332-15.18-25.588-9.44-9.782-29.925-17.782-29.925-17.782s16.134 10.235 19.95 21.685z"
        fill="#183D45"
      />
      <path
        d="M46.84 365.881c-11.71-14.023-30.36-36.864-41.636-41.635"
        stroke="#fff"
        strokeWidth={0.26}
      />
      <path
        d="M18.215 353.737c11.244-1.006 28.19 12.144 28.19 12.144s-18.135-5.517-28.19-6.015C11.092 359.513 0 363.279 0 363.279s10.444-8.846 18.215-9.542z"
        fill="#285964"
      />
      <path
        d="M.867 362.845s11.432-6.68 19.517-6.939c10.343-.331 24.72 9.541 24.72 9.541"
        stroke="#fff"
        strokeWidth={0.173}
      />
      <path
        d="M71.56 344.196c-3.817 11.45-15.324 15.758-25.154 21.685 0 0 7.212-17.332 15.179-25.588 9.44-9.782 29.925-17.782 29.925-17.782s-16.133 10.235-19.95 21.685z"
        fill="#183D45"
      />
      <path
        d="M46.405 365.881c11.71-14.023 30.359-36.864 41.635-41.635"
        stroke="#fff"
        strokeWidth={0.26}
      />
      <path
        d="M75.03 353.737c-11.244-1.006-28.19 12.144-28.19 12.144s18.135-5.517 28.19-6.015c7.123-.353 18.215 3.413 18.215 3.413s-10.444-8.846-18.215-9.542z"
        fill="#285964"
      />
      <path
        d="M92.378 362.845s-11.432-6.68-19.517-6.939c-10.343-.331-24.72 9.541-24.72 9.541"
        stroke="#fff"
        strokeWidth={0.173}
      />
    </g>
    <defs>
      <clipPath id="IllustrationSendFeedback_svg__clip0">
        <path fill="#fff" d="M0 0h365v367.526H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgIllustrationSendFeedback;
