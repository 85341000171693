import React from 'react';
import styled from 'styled-components';
import { colors, styles, icons } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';
import Section from '~/components/Section';
import Image from '~/components/Image';
import CTATry from '~/components/signup/CTATry';
import BeDataDriven from '~/components/startups/BeDataDriven';

import Working from '~/illustrations/WorkingAnimated';

const Styled = styled.div`
  .opener {
    margin-top: -4rem;
    padding-top: 6rem;
    padding-bottom: 2rem;
    position: relative;
  }
  .section__gradient {
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.25;
  }
  .opener h1 {
    font-size: 3rem;
    line-height: 3.3rem;
    width: 90%;
    margin: 1.5rem 0;
  }
  .opener p {
    font-size: 1.5rem;
    line-height: 1.9rem;
  }
  .opener__illustration > div {
    overflow: visible !important;
  }
  .opener__illustration svg {
    width: 40rem !important;
    height: 40rem !important;
    margin-top: -6rem;
    margin-left: -7rem;
  }
  .opener .container {
    flex-flow: row wrap;
  }
  .opener .signup-form {
    margin-top: 2rem;
    width: calc(100% + 8rem);
  }
  .opener .promo {
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    background: ${colors.PURPLE_LIGHT(0.1)};
    color: ${colors.PURPLE_LIGHT()};
    font-size: 0.9rem;
    width: fit-content;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .opener .promo label {
    font-size: 0.7rem;
    color: ${colors.PURPLE_LIGHT()};
  }

  .promo__trusted {
    margin-top: 3rem;
  }
  .promo__trusted__logos {
    display: flex;
    margin-top: 1rem;
  }
  .promo__trusted__logos__logo {
    width: 6rem;
    margin-right: 1.5rem;
    filter: grayscale();
    opacity: 0.6;
    transition: 250ms all;
    display: flex;
    align-items: center;
  }
  .promo__trusted__logos__logo:last-child {
    margin: 0;
  }
  .promo__trusted__logos__logo:hover {
    opacity: 1;
    filter: none;
  }
  .promo__trusted__logos__logo .gatsby-image-wrapper {
    overflow: visible !important;
    width: 100%;
  }
  .opener__data {
    margin-top: 2rem;
  }

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 860px) {
    .opener h1 {
      font-size: 2.8rem;
      line-height: 3.3rem;
    }
    .opener__illustration svg {
      width: 36rem !important;
      height: 36rem !important;
      margin-top: -4rem;
      margin-left: 0rem;
    }
  }
  @media screen and (max-width: 600px) {
    .opener .signup-form {
      width: fit-content;
    }
    .opener__data {
      margin-top: 3rem;
    }
    .opener__illustration {
      display: none;
    }
  }
  @media screen and (max-width: 500px) {
    .opener .promo {
      font-size: 1.2rem;
      white-space: initial;
      background: none;
      padding: 0;
      line-height: 1.6rem;
    }
    .opener .signup-form {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .trusted__logos {
      flex-flow: column;
    }
    .promo__trusted__logos {
      flex-flow: row wrap;
      justify-content: center;
    }
    .promo__trusted__logos__logo {
      margin: 1rem 0.5rem;
      width: 5rem;
    }
  }

  @media screen and (max-width: 400px) {
    .opener .container__text {
      width: 100%;
    }
    .opener .container__image {
      bottom: -20%;
    }
  }
`;

const customerLogos: { [key: string]: string } = {
  'openphone.png':
    'https://www.openphone.co/blog/how-we-collect-organize-and-implement-customer-feedback/',
  'cuanto.png': 'https://www.cuanto.app',
  'beacons.png': 'https://beacons.ai',
  'deepnote.png': 'https://deepnote.com',
};

const HomepageOpener: React.FC = () => {
  return (
    <Styled>
      <Section className="opener">
        <div className="section__gradient" />
        <Container className="container" grid={true}>
          <div className="section__container section__container--none">
            <div className="section__container__col section__container__col--4 section__container__col--mobile-center opener__text">
              <h1>Are you actually making something people want?</h1>
              <p>
                Herald helps you organize and quantify customer feedback to make
                better products.
              </p>
              <div className="signup-form">
                <CTATry primary={true} />
              </div>
              <div className="promo__trusted">
                <label className="text-gray">
                  Trusted by Customer Obsessed Teams
                </label>
                <div className="promo__trusted__logos">
                  {Object.keys(customerLogos).map((key) => (
                    <a
                      key={key}
                      href={customerLogos[key]}
                      className="promo__trusted__logos__logo"
                      target="_blank"
                    >
                      <Image src={`logos/${key}`} alt={key} />
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="section__container__col section__container__col--4 section__container__col--no-padding opener__illustration">
              <Working />
            </div>
            <div className="section__container__col section__container__col--8 opener__data">
              <BeDataDriven />
            </div>
          </div>
        </Container>
      </Section>
    </Styled>
  );
};

export default HomepageOpener;
