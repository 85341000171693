import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors, icons } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';
import { USE_CASES } from '~/components/use-cases/Opener';
import Section from '~/components/Section';
import Square, { StyledSquareItem } from '~/components/startups/SquareBlock';

const Styled = styled.div`
  .container {
    display: block !important;
  }
  .label {
    margin: auto;
  }
  .flexibility__description {
    margin: 2rem auto;
    text-align: center;
    max-width: 60rem;
  }
  .flexibility__square {
    position: relative;
    margin: 1rem -2rem;
    margin-top: 4rem;
    border-top: 1px dashed ${colors.GRAY_3(0.2)};
    border-bottom: 1px dashed ${colors.GRAY_3(0.2)};
  }
  .square__item__title {
    display: flex;
    align-items: center;
    color: ${colors.PURPLE_LIGHT()};
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  .square__item__title svg {
    fill: ${colors.PURPLE_LIGHT()};
    transition: 125ms all;
    margin-left: 0.25rem;
  }
  .square__item__title:hover svg {
    transform: translateX(10px);
  }
  .square__item__description {
    margin-top: 0.5rem;
    font-size: 1rem;
    line-height: 1.2rem;
    opacity: 0.8;
  }
  .square__item__icon {
    fill: ${colors.GRAY_3(0.6)};
    width: 1.5rem;
    height: 1.5rem;
  }
  @media screen and (max-width: 600px) {
    .square__item:nth-child(3) {
      border-top: 1px dashed ${colors.GRAY_3(0.2)};
    }
    .square__item:nth-child(4) {
      border-top: 1px dashed ${colors.GRAY_3(0.2)};
    }
  }

  @media screen and (max-width: 400px) {
  }
`;

const SquareItem: React.FC<any> = (props) => {
  const { icon: Icon, title, description, link } = props;
  return (
    <StyledSquareItem className="square__item">
      <Link to={link}>
        <h5 className="square__item__title">
          {title} <icons.Arrow />
        </h5>
      </Link>
      <div
        className="square__item__indicator"
        style={{ top: 28, height: 24 }}
      />
      <Icon className="square__item__icon" />
      <p className="square__item__description">{description}</p>
    </StyledSquareItem>
  );
};

const HomepageFlexibility: React.FC = () => {
  return (
    <Styled>
      <Section className="section flexibility">
        <Container className="container">
          <div className="label">
            <label>Flexible</label>
          </div>
          <h4 className="flexibility__description text-gray">
            Herald molds to the way your team works.
            <br className="drop-at-med" /> Pick and choose the features that
            matter to you.
          </h4>
          <Square className="square--data flexibility__square">
            <SquareItem
              icon={icons.Toolbox}
              title="Private"
              description={
                <>
                  Use Herald as <strong>an internal tool</strong> to organize
                  and quantify customer feedback to make better products.
                </>
              }
              link={`/use-cases/${USE_CASES[0].slug}/`}
            />
            <SquareItem
              icon={icons.Feather}
              title="Semi-Private"
              description={
                <>
                  Foster trust and transparency with customers by privately
                  sharing a <strong>history of their feedback</strong> with
                  them.
                </>
              }
              link={`/use-cases/${USE_CASES[3].slug}/`}
            />
            <SquareItem
              icon={icons.Bullseye}
              title="Public"
              description={
                <>
                  Keep all customers in the loop by publishing a{' '}
                  <strong>public roadmap</strong>.
                </>
              }
              link={`/use-cases/${USE_CASES[3].slug}/`}
            />
            <SquareItem
              icon={icons.Pennant}
              title="Public"
              description={
                <>
                  Be transparent about the features you’ve built with a{' '}
                  <strong>beautiful changelog</strong>.
                </>
              }
              link={`/use-cases/${USE_CASES[3].slug}/`}
            />
          </Square>
        </Container>
      </Section>
    </Styled>
  );
};

export default HomepageFlexibility;
