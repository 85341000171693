import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';
import Section from '~/components/Section';
import Image from '~/components/Image';

import SendFeedback from '~/illustrations/SendFeedback';
import Arrow from '~/icons/Arrow';

import { USE_CASES } from '~/components/use-cases/Opener';

const Styled = styled.div`
  .align__illustration {
    width: 16rem;
    height: 16rem;
    margin: -6rem 0;
  }
  .align__illustration svg {
    width: 100%;
    height: 100%;
  }
  .align__screenshots {
    position: relative;
  }
  .align__screenshots__voc {
    z-index: 0;
    width: 28rem;
    position: absolute;
    top: -2rem;
    left: 0rem;
  }
  .align__screenshots__slack {
    width: calc(100% - 5rem);
    position: absolute;
    z-index: 1;
    right: 0;
    margin-top: 10rem;
  }
  .align__tools {
    margin-top: 2rem;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
  }
  .align__tools__logos {
    padding: 1rem;
    background: ${colors.GRAY_2(0.5)};
    border-radius: 3px;
    display: flex;
    margin-top: 1rem;
    width: fit-content;
  }
  .align__tools__logos__logo {
    width: 1.5rem;
    height: 1rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .align__tools__logos__logo:last-child {
    margin: 0;
  }
  .align__tools__logos__logo .gatsby-image-wrapper {
    width: 100%;
  }
  /* END SECTION */

  @media screen and (max-width: 1200px) {
    .align__screenshots__voc {
      bottom: -6rem;
    }
  }

  @media screen and (max-width: 1024px) {
    .align__screenshots__voc {
      bottom: -2rem;
    }
  }

  @media screen and (max-width: 800px) {
    .align__screenshots__slack {
      width: 70%;
      left: 30%;
    }
    .align__illustration {
      height: 20rem;
      width: 20rem;
      margin-left: -12rem;
      margin-bottom: -4rem;
    }
  }
  @media screen and (max-width: 600px) {
    .align__tools {
      align-items: center;
    }
    .align__screenshots__slack {
      position: relative;
      width: 100%;
      margin-bottom: -6rem;
    }
    .align__screenshots__voc {
      bottom: initial;
      top: 2rem;
    }
    .align__illustration {
      display: none;
    }
  }

  @media screen and (max-width: 400px) {
  }
`;

const keepTeamAlignedLogos = ['jira', 'github', 'linear', 'gitlab', 'slack'];

const HomepageAlign: React.FC = () => {
  return (
    <Styled>
      <Section className="section align" id="align">
        <Container className="container">
          <div className="section__container">
            <div className="section__container__col section__container__col--4 prioritize__text">
              <div className="label">
                <label>Step 3</label>
              </div>

              <h3 style={{ marginBottom: 24 }}>{USE_CASES[2].title}</h3>
              <h4 className="text-gray" style={{ marginBottom: 12 }}>
                Don't waste time and energy getting on the same page.
              </h4>
              <p>
                Herald's Voice of the Customer report, along with Slack and
                issue tracker integrations keep your entire team connected to
                customer needs.
              </p>
              <Link
                to={`/use-cases/${USE_CASES[2].slug}/`}
                className="section__use-case-container"
              >
                <div className="section__link">
                  Learn More <Arrow className="section__link__arrow" />
                </div>
              </Link>
              <div className="align__tools">
                <label>Integrations</label>
                <div className="align__tools__logos">
                  {keepTeamAlignedLogos.map((logo) => (
                    <div
                      key={logo}
                      className={`align__tools__logos__logo align__tools__logos__logo--${logo}`}
                    >
                      <Image src={`logos/${logo}.png`} />
                    </div>
                  ))}
                </div>
              </div>
              <div className="align__illustration">
                <SendFeedback />
              </div>
            </div>
            <div className="section__container__col section__container__col--4 section__container__col--no-padding align__screenshots">
              <div className="screenshot align__screenshots__slack">
                <Image src="screenshots/take-slack.png" />
              </div>
              <div className="screenshot align__screenshots__voc">
                <Image src="screenshots/voice-of-the-customer.png" />
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </Styled>
  );
};

export default HomepageAlign;
