import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

const SEGMENTS = [
  'the loudest opinions.',
  'recent feedback.',
  'strong gut feelings.',
];
const SEGMENT_LENGTH_MILLISECONDS = 3000;
const SEGMENT_TRANSITION_LENGTH_MILLISECONDS = 250;

const Styled = styled.div`
  .enter {
    opacity: 0;
  }
  .enter-active {
    opacity: 1;
    transition: opacity ${SEGMENT_TRANSITION_LENGTH_MILLISECONDS}ms;
  }
  .exit {
    opacity: 1;
  }
  .exit-active {
    opacity: 0;
    transition: opacity ${SEGMENT_TRANSITION_LENGTH_MILLISECONDS}ms;
  }
`;

const SegmentsCarousel: React.FC = () => {
  const [segment, setSegment] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const s = segment;
      setSegment(-1);
      setTimeout(() => {
        setSegment(() => {
          if (s === SEGMENTS.length - 1) {
            return 0;
          }
          return s + 1;
        });
      }, SEGMENT_TRANSITION_LENGTH_MILLISECONDS);
    }, SEGMENT_LENGTH_MILLISECONDS);
    return () => {
      clearInterval(interval);
    };
  }, [segment]);

  return (
    <Styled>
      <h4 className="section__subtitle text-gray">
        Don't let your product roadmap be biased by{' '}
        {SEGMENTS.map((s, i) => (
          <CSSTransition
            key={s}
            in={i === segment}
            timeout={SEGMENT_TRANSITION_LENGTH_MILLISECONDS}
            unmountOnExit={true}
            mountOnEnter={true}
          >
            <span key={s}>{s}</span>
          </CSSTransition>
        ))}
      </h4>
    </Styled>
  );
};

export default SegmentsCarousel;
