import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Container } from '~/layout/Layout';
import Section from '~/components/Section';
import Image from '~/components/Image';

import SegmentsCarousel from '~/components/startups/CustomerSegments';
import Arrow from '~/icons/Arrow';

import { USE_CASES } from '~/components/use-cases/Opener';

const Styled = styled.div`
  .qualify__text {
    padding-right: 4rem;
  }
  @media screen and (max-width: 1200px) {
  }

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 800px) {
    .qualify__screenshots__quotes-filter {
      width: 100%;
      min-width: 550px;
    }
  }

  @media screen and (max-width: 600px) {
    .qualify__screenshots {
      padding: 2rem;
      margin-bottom: -6rem;
    }
  }

  @media screen and (max-width: 400px) {
  }
`;

const HomepageQualify: React.FC = () => {
  return (
    <Styled>
      <Section className="section section--gray qualify" id="qualify">
        <Container>
          <div className="section__container">
            <div className="section__container__col section__container__col--4 qualify__text">
              <div className="label">
                <label>Step 2</label>
              </div>

              <h3 className="section__title">{USE_CASES[1].title}</h3>
              <SegmentsCarousel />
              <p className="section__description">
                Precisely understand what prospective customers desire,
                onboarding pains felt by new customers, or additional features
                needed by your largest customers.
              </p>
              <Link
                to={`/use-cases/${USE_CASES[1].slug}/`}
                className="section__use-case-container"
              >
                <div className="section__link">
                  Learn More <Arrow className="section__link__arrow" />{' '}
                </div>
              </Link>
            </div>
            <div className="section__container__col section__container__col--4 section__container__col--no-padding qualify__screenshots">
              <div className="screenshot qualify__screenshots__quotes-filter">
                <Image src="screenshots/personas-filter.png" />
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </Styled>
  );
};

export default HomepageQualify;
