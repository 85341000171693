import React from 'react';
import styled from 'styled-components';
import { colors, styles } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';
import Page from '~/layout/LayoutMarketing';
import Section from '~/components/Section';
import LargeCTA from '~/components/LargeCTA';
import Grid from '~/components/startups/HomepageGrid';

import TestimonialsCarousel from '~/components/startups/TestimonialsCarousel';

import HomepageOpener from '~/components/startups/sections/StartupsOpener';
import HomepageConsolidate from '~/components/startups/sections/StartupsConsolidate';
import HomepageQualify from '~/components/startups/sections/StartupsQualify';
import HomepageAlign from '~/components/startups/sections/StartupsAlign';
import HomepageEngage from '~/components/startups/sections/StartupsEngage';
import HomepageFlexibility from '~/components/startups/sections/StartupsFlexibility';

const Styled = styled.div`
  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .screenshot .gatsby-image-wrapper {
    box-shadow: ${styles.BOX_SHADOW};
    border-radius: ${styles.BORDER_RADIUS};
  }
  .section__link {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  .section__link svg.section__link__arrow {
    margin: 0 !important;
    margin-left: 0.5rem !important;
    fill: ${colors.PURPLE_LIGHT()};
    width: 1.5rem !important;
    height: 2rem !important;
    position: initial !important;
    transition: 250ms all;
  }
  .section__link:hover svg.section__link__arrow {
    transform: translateX(10px);
  }
  .label {
    padding: 0.55rem 0.7rem;
    background: ${colors.GRAY_2()};
    width: fit-content;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  .label--dark {
    background: ${colors.PURPLE_DARK(0.8)};
  }
  .label label {
    color: ${colors.GRAY_3()};
  }
  .label--dark label {
    color: ${colors.WHITE()};
  }

  /* TESTIMONIALS SECTION */
  .section--testimonials {
    padding-bottom: 3rem;
  }
  /* END SECTION */

  @media screen and (max-width: 1200px) {
  }

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 800px) {
    .container {
      flex-flow: column;
    }
  }

  @media screen and (max-width: 500px) {
  }

  @media screen and (max-width: 400px) {
  }
`;

const IndexPage: React.FC = () => {
  return (
    <Page
      title="Organize and quantify customer feedback"
      description="Herald helps you organize and quantify customer
      feedback to make better products."
    >
      <Styled>
        <Grid />

        <HomepageOpener />
        <HomepageConsolidate />
        <HomepageQualify />
        <HomepageAlign />
        <HomepageEngage />
        <HomepageFlexibility />

        <Section className="section--testimonials">
          <Container className="container">
            <TestimonialsCarousel />
          </Container>
        </Section>
        <LargeCTA />
      </Styled>
    </Page>
  );
};

export default IndexPage;
