import React from 'react';
import styled from 'styled-components';
import { colors, icons } from 'herald-fe-shared';

import Square, { SquareItem } from '~/components/startups/SquareBlock';

const Styled = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  flex: 0 0 100%;
  .label {
    margin: auto;
  }
  .promo__data__description {
    margin: 2rem auto;
    text-align: center;
    max-width: 60rem;
  }
  .promo__data__square {
    position: relative;
    margin: 1rem -2rem;
    margin-top: 4rem;
  }
  .square__item__icon {
    fill: ${colors.PURPLE_LIGHT()};
    width: 2rem;
    height: 2rem;
    margin-bottom: 1rem;
  }
  .square__item__title {
    display: flex;
    align-items: center;
    color: ${colors.PURPLE_LIGHT()};
    margin-bottom: 0.5rem;
  }
  .square__item__title svg {
    fill: ${colors.PURPLE_LIGHT()};
    transition: 125ms all;
    margin-left: 0.25rem;
  }
  .square__item__title:hover svg {
    transform: translateX(10px);
  }
  .promo__data__animation {
    position: absolute;
    left: 0;
    top: -1rem;
    width: 100%;
    height: calc(100% + 2rem);
    z-index: -1;
    border: 1px dashed ${colors.PURPLE_LIGHT(0.5)};
  }
  .promo__data__animation__circle {
    position: absolute;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background: ${colors.PURPLE_LIGHT(0.5)};
    left: -0.3rem;
    top: -0.3rem;
  }
  .promo__data__animation__circle:nth-child(1) {
    animation: circleRotate 5s infinite;
  }
  .promo__data__animation__circle:nth-child(2) {
    animation: circleRotate 4s infinite;
    animation-delay: 1s;
  }
  .promo__data__animation__circle:nth-child(3) {
    animation: circleRotate 8s infinite;
    animation-delay: 4s;
  }
  .promo__data__animation__circle:nth-child(4) {
    animation: circleRotate 10s infinite;
    animation-delay: 3s;
  }

  @keyframes circleRotate {
    0% {
      left: -0.3rem;
      top: -0.3rem;
    }
    25% {
      left: calc(100% - 0.3rem);
      top: -0.3rem;
    }
    50% {
      left: calc(100% - 0.3rem);
      top: calc(100% - 0.3rem);
    }
    75% {
      left: -0.3rem;
      top: calc(100% - 0.3rem);
    }
  }

  @media screen and (max-width: 500px) {
  }

  @media screen and (max-width: 400px) {
  }
`;

const BeDataDriven: React.FC = () => {
  return (
    <Styled className="promo__data-driven">
      {/*<div className="label label--dark">
        <label>Be Data Driven</label>
      </div>
      <h4 className="promo__data__description text-gray">
        Herald makes it easy to get data-driven about product development. Use
        it to fuel a virtuous cycle of continuous customer feedback.
  </h4> */}
      <Square className="square--data promo__data__square">
        <SquareItem
          icon={icons.Atlas}
          title="Consolidate"
          description="Keep all customer feedback organized in one place."
          top={84}
          link="/startups#consolidate"
          step={1}
        />
        <SquareItem
          icon={icons.Gem}
          title="Qualify"
          description="Rigorously qualify and quantify feedback according to business goals."
          top={84}
          link="/startups#qualify"
          step={2}
        />
        <SquareItem
          icon={icons.LocationCircle}
          title="Align"
          description="Keep your team aligned about what customers are saying."
          top={84}
          link="/startups#align"
          step={3}
        />
        <SquareItem
          icon={icons.CommentAltDots}
          title="Engage"
          description="Keep your customers engaged throughout the development process."
          top={84}
          link="/startups#engage"
          step={4}
        />
        <div className="promo__data__animation">
          <div className="promo__data__animation__circle" />
          <div className="promo__data__animation__circle" />
          <div className="promo__data__animation__circle" />
          <div className="promo__data__animation__circle" />
        </div>
      </Square>
    </Styled>
  );
};

export default BeDataDriven;
