import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Container } from '~/layout/Layout';
import Section from '~/components/Section';
import Image from '~/components/Image';

import Network from '~/illustrations/Network';
import Arrow from '~/icons/Arrow';

import { USE_CASES } from '~/components/use-cases/Opener';

const Styled = styled.div`
  .engage__illustration {
    position: absolute;
    width: 28rem;
    height: 28rem;
    bottom: -10rem;
    left: 85%;
  }
  .engage__illustration svg {
    width: 100%;
    height: 100%;
  }
  .engage__screenshots {
    position: relative;
  }
  .engage__screenshots__gmail {
    width: 80%;
    top: -2rem;
    left: 0;
    position: absolute;
  }
  .engage__screenshots__apple {
    width: 80%;
    position: absolute;
    right: 0;
    bottom: -2rem;
    z-index: 2;
  }

  @media screen and (max-width: 1200px) {
  }

  @media screen and (max-width: 1024px) {
    .engage__screenshots__gmail {
      width: 90%;
    }
    .engage__screenshots__apple {
      width: 90%;
      bottom: initial;
      top: 8rem;
    }
  }

  @media screen and (max-width: 600px) {
    .section__container:after {
      left: 0;
    }
    .engage__screenshots {
      grid-row: 2;
      margin-top: 2rem;
      grid-column: span 4;
    }
    .engage__screenshots__gmail {
      position: relative;
      top: 0;
    }
    .engage__illustration {
      top: 0;
      left: 75%;
      width: 20rem;
      height: 20rem;
    }
  }

  @media screen and (max-width: 400px) {
  }
`;

const HomepageEngage: React.FC = () => {
  return (
    <Styled>
      <Section className="section section--gray engage" id="engage">
        <Container className="container">
          <div className="section__container section__container--2">
            <div className="section__container__col section__container__col--4 section__container__col--no-padding engage__screenshots">
              <div className="screenshot engage__screenshots__gmail">
                <Image src="screenshots/engage-gmail.png" />
              </div>
              <div className="screenshot serve__screenshot engage__screenshots__apple">
                <Image src="screenshots/engage-apple.png" />
              </div>
            </div>
            <div className="section__container__col section__container__col--3 engage__text">
              <div className="label">
                <label>Step 4</label>
              </div>
              <h3 style={{ marginBottom: 24 }}>{USE_CASES[3].title}</h3>
              <h4 className="text-gray" style={{ marginBottom: 12 }}>
                Don't miss opportunities for more feedback.
              </h4>
              <p>
                Usher in a culture of hospitality by invovling customers in the
                design phase or informing them when you ship something they
                wanted.
              </p>
              <Link
                to={`/use-cases/${USE_CASES[3].slug}/`}
                className="section__use-case-container"
              >
                <div className="section__link">
                  Learn More <Arrow className="section__link__arrow" />
                </div>
              </Link>
              <div className="engage__illustration">
                <Network />
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </Styled>
  );
};

export default HomepageEngage;
