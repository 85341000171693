import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { utils } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';
import Section from '~/components/Section';
import Image from '~/components/Image';

import AppsCarousel from '~/components/startups/AppsCarousel';
import Editing from '~/illustrations/Editing';
import Arrow from '~/icons/Arrow';

import { USE_CASES } from '~/components/use-cases/Opener';
import { APPS } from '~/components/works-with/Opener';

const Styled = styled.div`
  .collect__supports {
    margin-top: 2rem;
  }
  .collect__logos {
    display: flex;
    margin-top: 1rem;
  }
  .collect__logos__logo {
    width: 1.25rem;
    height: 1rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .collect__logos__logo .gatsby-image-wrapper {
    width: 100%;
  }
  .collect__logos__logo--gdocs {
    width: 1rem;
  }
  .collect__logos__logo--link {
    transition: 250ms all;
  }
  .collect__logos__logo--link:hover {
    transform: scale(1.6);
  }
  .collect__illustration {
    width: 14rem;
    height: 11rem;
    margin-top: 2rem;
    margin-bottom: -7rem;
  }
  .collect__illustration svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1200px) {
  }

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 800px) {
    .container--collect {
      position: initial;
    }
    .collect__logos {
      margin-top: 1.5rem;
    }
    .collect__illustration {
      margin-bottom: -5rem;
    }
  }

  @media screen and (max-width: 600px) {
    .collect__screenshots {
      margin-top: 2rem;
      position: relative;
      z-index: 2;
    }
    .collect__screenshot {
      height: 20rem;
    }
    .collect__illustration {
      position: absolute;
      right: 0rem;
      bottom: 26rem;
      z-index: 0;
      margin: 0;
    }
  }

  @media screen and (max-width: 400px) {
    .collect__screenshot {
      height: 16rem;
    }
    .collect__illustration {
      display: none;
    }
  }
`;

const HomepageConsolidate: React.FC = () => {
  return (
    <Styled>
      <Section className="section collect" id="consolidate">
        <Container>
          <div className="section__container">
            <div className="section__container__col section__container__col--4">
              <div className="label">
                <label>Step 1</label>
              </div>

              <h3 className="section__title">{USE_CASES[0].title}</h3>
              <h4 className="section__subtitle text-gray">
                Don't let customer feedback be lost or siloed.
              </h4>
              <p className="section__description">
                Our{' '}
                {utils.environment?.firefox
                  ? 'Firefox Addon'
                  : 'Chrome Extension'}
                , Slack app, and Front plugin allow your team to consolidate
                customer feedback from support tickets, email threads, or chat
                conversations with a single click.
              </p>
              <Link
                to={`/use-cases/${USE_CASES[0].slug}/`}
                className="section__use-case-container"
              >
                <div className="section__link">
                  Learn More <Arrow className="section__link__arrow" />{' '}
                </div>
              </Link>
              <div className="collect__supports">
                <label>Supports</label>
                <div className="collect__logos">
                  {APPS.map((l: any) => {
                    const logo = (
                      <div
                        key={l.id}
                        className={`collect__logos__logo collect__logos__logo--${
                          l.id
                        } collect__logos__logo--${l.slug ? 'link' : 'no-link'}`}
                      >
                        <Image src={`logos/${l.logo}.png`} />
                      </div>
                    );
                    return l.slug ? (
                      <Link to={`/works-with/${l.slug}`} key={l.id}>
                        {logo}
                      </Link>
                    ) : (
                      logo
                    );
                  })}
                  <div className="collect__logos__more text-gray">+ More</div>
                </div>
                <div className="collect__illustration">
                  <Editing />
                </div>
              </div>
            </div>
            <div className="section__container__col section__container__col--4 section__container__col--no-padding collect__screenshots">
              <AppsCarousel />
            </div>
          </div>
        </Container>
      </Section>
    </Styled>
  );
};

export default HomepageConsolidate;
